import React, {Component} from 'react';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import ProjectGateway from "../Gateways/ProjectGateway";
import {Callout, Colors} from "react-foundation";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            projects: []
        };

    }

    loadedProjects = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,projects: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {
        ProjectGateway.LoadAllProjects(this.loadedProjects,this.errorLoading);
    }


    render() {
        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const Projects = !this.state.loading && this.state.projects && this.state.projects.map(
            project => (<li key={project.id}> {project.name}</li>));
        if (this.state.loading) {
            return(
                <h1>
                <FontAwesomeIcon icon={faCoffee} />
                Page is Loading
                 <FontAwesomeIcon icon={faSpinnerThird} spin />
                </h1>
            );
        }
        else {
            return(
                <div>
                    {DivErrorMessage}
                    <ul>
                        {Projects}
                    </ul>

                </div>

            );
        }
    }
}

export default HomePage;
