import axios from 'axios';

class ProjectGateway
{
    static LoadAllProjects(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/project/').then(successCallback).catch(errorCallback);
    }

    static add(name,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/project/',{
            name: name
        }).then(successCallback).catch(errorCallback);
    }

    static update(project_id,name,successCallback,errorCallback)
    {
        axios.put(process.env.REACT_APP_API_URL+'/project/'+project_id,{
            name: name
        }).then(successCallback).catch(errorCallback);
    }
}

export default ProjectGateway;