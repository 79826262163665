import axios from 'axios';

class UserGateway
{
    static login(username,password,g_recaptcha_response,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/auth/',
            {
                username: username,
                password: password,
                g_recaptcha_response: g_recaptcha_response
            }).then(successCallback).catch(errorCallback);
    }

    static register(username,password,g_recaptcha_response,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/register/',{
            username: username,
            password: password
        }).then(successCallback).catch(errorCallback);
    }

    static getMe(successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/').then(successCallback).catch(errorCallback);
    }
}

export default UserGateway;