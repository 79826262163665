import React, {Component} from 'react';
// import {Callout, Colors} from "react-foundation";

class Footer extends Component {

    constructor(props) {
        super(props);
        // this.state = {loading: true, errorMessage: undefined}
        this.state = {loading: false, errorMessage: undefined}
    }



    render() {
        return(
            // <Callout color={Colors.PRIMARY}>Footer Content</Callout>
            <p> HUHU</p>
        )
    }
}

export default Footer;
